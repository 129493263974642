<template>
    <div class="shop_main noMenu">
        <div class="shop_order_main">
            <userMenu type="8" />

            <div class="shopKeepList" style="margin-right: 0">
                <Menu :type="2" />

                <div class="storeList" v-if="listData.length > 0">
                    <div class="storeItem" v-for="(item, index) in listData" :key="index">
                        <div class="storeInfo">
                            <img src="../../../assets/img/shop_order.png" class="icon" />
                            <h1 class="name">{{item.storeName}}</h1>

                            <a href="javascript:void(0)" class="keep" @click="toCollect(item.storeId)">
                                <img src="../../../assets/img/store_keep_yes.png" />
                                <span>取消收藏</span>
                            </a>
                        </div>

                        <div class="goods">
                            <a href="javascript:void(0)" class="item" v-for="good in item.goodsList" :key="good.id" @click="$router.push({path: '/shop/detail', query: {id: good.id}})">
                                <div class="image">
                                    <el-image :src="imageURL + good.imageUrl" fit="contain"></el-image>
                                </div>

                                <span class="name">{{good.name}}</span>

                                <span class="price">¥ {{good.price}}</span>
                            </a>
                        </div>
                    </div>
                </div>

                <noDataView v-else/>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {imageURL} from "../../../utils/config";
  import {getShopStoreCollectList, postShopStoreCollect} from "../../../api/shop/store";
  const userMenu = () => import('../../../components/layout/shop/userMenu');
  const Menu = () => import('./menu');
  const noDataView = () => import('../../../components/noDataView');
  export default {
    name: "shopKeepStore",
    data () {
      return {
        listData: [],
        isRequestLoading: false,
        isSearchLoading: false,
        imageURL: imageURL,
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getShopStoreCollectList().then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.listData = res.data
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      toCollect (id) {
        this.$confirm('此操作将取消关注该店铺, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postShopStoreCollect(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '店铺取消关注成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      }
    },
    components: {
      userMenu, Menu, noDataView
    }
  }
</script>
